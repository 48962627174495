import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import instance  from './axios.js'
import store from './store'
import AudioVisual from 'vue-audio-visual'
import Notifications from 'vue-notification'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import AudioRecorder from 'vue-audio-recorder'

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};
import moment from 'moment';

Vue.prototype.$moment = moment;

Vue.use(AudioRecorder)
Vue.use(VueSweetalert2, options);
Vue.use(Notifications)
Vue.use(AudioVisual)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)

Vue.prototype.$axios = instance;




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
