import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router';
import instance from '../axios.js';

const axios = instance;

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: localStorage.getItem('token'),
    admin: '',
    groups: [],
    ani_list: [],
    audios: [],
    items: [],
    loading: false,
    user: null,
  },
  mutations: {
    updateToken(state, token) {
      state.token = token.token;
      if (!localStorage.getItem('token')) {
        localStorage.setItem('token', token.token);
        localStorage.setItem('refresh', token.refresh);
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      }
    },
    updateItems(state, value) {
      state.items = value;
    },
    updateAdmin(state, value) {
      state.admin = value ? value : '';
    },
    updateGroups(state, value) {
      const ani = value[0]?.ani_list
      console.log(value, ani)
      if (ani) {
        state.ani_list = value.map(el => {
          return {
            text: el.name,
            value: el.id,
            ...el,
          }
        });
      }
      state.groups = value;
    },
    updateFiles(state, value) {
      state.audios = value;
    },
    updateLoading(state, value) {
      state.loading = value;
    },
    updateUserInfo(state, value) {
      state.user = value;
    }
  },
  actions: {
    auth({ commit, dispatch }) {
      const token = localStorage.getItem('token');
      // let time = parseInt(localStorage.getItem('time'));
      // let now = Date.now();
      // let item = now - time;
      // const hours = parseInt((item / (1000 * 60 * 60)) % 24);
      // console.log(hours)
      // if (hours == 0) {
      //   dispatch('refresh')
      // }
      if (token) {
        commit('updateToken', token)
      } else {
        router.push({ name: 'login' })
      }
    },
    refresh({ commit }) {
      let config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('refresh')}`
        }
      }
      let data = null
      axios.post(`/token/refresh`, data, config).then(res => {
        localStorage.removeItem('token');
        commit("updateToken", { token: res.data.access_token });
        location.reload()
      }).catch(err => {
        localStorage.clear();
        location.reload()
      })
    },
    async loadUserInfo({ commit }) {
      commit("updateLoading", true);
      await axios
        .get("/user/info")
        .then((res) => {
          commit('updateUserInfo', res.data)
          commit("updateLoading", false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async loadItems({ state, dispatch, commit }) {
      await axios
        .get(`${state.admin}/campaigns/list`)
        .then((res) => {
          commit('updateItems', res.data.campaigns);
          commit("updateLoading", false);
        })
        .catch((err) => {
          console.log()
          if (err.response.status == 401) dispatch('refresh')
        });
    },
    loadGroups({ state, commit }, ani) {
      commit('updateGroups', [])
      let url = '/contacts/groups/list'
      if (ani) url = '/ani_groups/list'
      axios.get(url).then(res => {
        commit('updateGroups', res.data.groups)
        commit("updateLoading", false);
      }).catch(err => {
        console.log(err)
      })
    },
    loadFiles({ state, commit, dispatch }, type) {
      axios
        .get(`${state.admin}/media/list`)
        .then((res) => {
          const data = res.data.files;
          if (data.length > 0) {
            const items = res.data.files.map((el) => {
              return {
                file: el.file,
                text: el.file.split("/media/")[1],
                media_id: el.media_id,
                owner: el.owner,
                timeCreated: new Date().toLocaleDateString(),
                audio: null,
                value: el.media_id,
              };
            });
            if (type !== 'info') {
              dispatch('getAudioFiles', items)
            } else {
              commit('updateFiles', items)
            }
          } else {
            commit('updateFiles', [])
            commit('updateLoading', false);
          }
        })
        .catch((err) => console.log(err));
    },
    getAudioFiles({ commit }, items) {
      items.forEach(async (el, index) => {
        await axios(`/media/download/${el.media_id}`, {
          media_id: el.media_id,
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'audio/mpeg'
          }
        })
          .then((res) => {
            const blob = new Blob([res.data], {
              type: 'audio/mpeg'
            });
            const url = URL.createObjectURL(blob);
            el['audio'] = url;
            commit('updateFiles', items)
            commit('updateLoading', false);
          })
          .catch((e) => console.log(e))
      })
    },
  },
  getters: {
    groups: state => state.groups,
    audios: state => state.audios,
    loading: state => state.loading,
    admin: state => state.admin,
    items: state => state.items
  }
})

export default store;