<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav pt-2">
        <li class="nav-item nav-profile">
          <router-link :to="{ name: 'profile' }" class="nav-link d-flex">
            <div class="profile-image">
              <img src="@/assets/images/faces/face5.jpg" alt="image" />
            </div>
            <div class="profile-name">
              <p class="name">{{ username }}</p>
              <p class="designation">
                {{ this.$store.state.admin ? "Admin" : "User" }}
              </p>
            </div>
          </router-link>
        </li>
        <li class="nav-item" id="top-up" v-on:click="collapseAll">
          <router-link class="nav-link" to="/invoice">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Top Up</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Dashboard</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/campaign-reporting">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Campaign Reporting</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/report">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Report</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/call-logs">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Call logs</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/credits">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Credits</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/transactions">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Transactions</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll" v-if="$store.state.admin">
          <router-link class="nav-link" to="/users">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Manage Users</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/audio-manage">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Manage Audio</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/groups">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Manage Contact Groups</span>
          </router-link>
        </li>
        <!-- <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/contacts">
            <span class="menu-title">Manage Contacts</span>
          </router-link>
        </li> -->
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/ani">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Manage ANI</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/reporting">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Reporting</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/invoice">
            <!-- <i class="mdi mdi-shield-check menu-icon"></i> -->
            <span class="menu-title">Invoice</span>
          </router-link>
        </li>
        <li class="nav-item" @click="logout">
          <router-link id="logout" class="nav-link no-active" to="">
            <span>Logout</span>
          </router-link>
        </li>
        <!-- <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/demo/">
            <i class="mdi mdi-shield-check menu-icon"></i>
            <span class="menu-title">FAQ</span>
          </router-link>
        </li> -->
      </ul>
    </nav>
  </section>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      collapses: [{ show: false }, { show: false }, { show: false }],
    };
  },

  methods: {
    collapseAll() {
      var exp_element = document.getElementsByClassName("show");
      if (exp_element.length > 0) {
        var elm_id = exp_element[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    async logout() {
      try {
        this.$axios.post("/logout/access");
        // this.$axios.post("/logout/refresh");
        this.$router.push({ name: "login" });
        localStorage.clear();
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    username() {
      return localStorage.getItem("username");
    },
  },
  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  },
  watch: {
    $route() {
      document.querySelector("#sidebar").classList.remove("active");
    },
  },
};
</script>
<style scoped>
.no-active {
  background: none !important;
}
@media (min-width: 991px) {
  #logout,
  #top-up {
    display: none;
  }
}
</style>