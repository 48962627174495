<template>
    <div id="app" :class="{'screen-freeze': $store.state.loading}">
      <router-view :class="{'overflow-hidden': $store.state.loading}"></router-view>
      <Loader v-if="loading" />

      <notifications position="bottom right" group="foo" />
    </div>
</template>

<script>
import Loader from "@/components/Loader";
import { Settings } from "luxon";
export default {
  name: "app",
  components: { Loader },
  mounted() {
    Settings.defaultLocale = "en";

    this.$store.dispatch("auth");
    const token = localStorage.getItem("token");
    if (token) {
      this.$axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      this.$router.push('/')
    }
    this.$store.commit("updateAdmin", localStorage.getItem("admin"));
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/vue-snotify/styles/material.css";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../node_modules/c3/c3.min.css";
@import "../node_modules/chartist/dist/chartist.min.css";
@import "./assets/scss/style";
.btn {
  height: 35px;
}
.groups {
  overflow-x: auto;
}
.ar {
  width: 100% !important;
  background: none !important;
  box-shadow: none !important;
  svg {
    vertical-align: top;
  }
  .ar-content {
    padding: 0 16px;
  }
  .ar__rm {
    top: 50%;
    left: 15px;
    font-size: 20px;
    transform: translateY(-50%);
    bottom: auto;
    padding: 0;
  }
  .ar-recorder__records-limit {
    top: 55px;
  }
  .ar-recorder__duration {
    margin-top: 24px;
  }
  .ar-records {
    width: 100%;
    height: auto !important;
    .ar-records__record {
      width: 100%;
    }
  }
  .ar-player {
    width: 100%;
    & > .ar-player-bar {
      width: 95%;
    }
  }
}
.dropdown {
  list-style: none;
}
.auth .brand-logo img {
  width: 85px;
}
.modal.show .modal-dialog {
  overflow-x: hidden;
}
.screen-freeze {
  position: relative;
}
.screen-freeze:after {
  content: '';
  width: 100%;
  position: absolute;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
