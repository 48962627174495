<template>
  <b-navbar
    id="template-header"
    class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
    toggleable="false"
    :style="{ background: '#fff' }"
  >
    <div
      class="text-left navbar-brand-wrapper d-flex align-items-center justify-content-between"
    >
      <a class="navbar-brand brand-logo" href="index.html"
        ><img src="/img/logo.jpeg" alt="logo"
      /></a>
      <a class="navbar-brand brand-logo-mini" href="index.html"
        ><img src="/img/logo.jpeg" alt="logo"
      /></a>
      <button
        class="navbar-toggler align-self-center"
        type="button"
        data-toggle="minimize"
        @click="toggleSidebar()"
      >
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
    <div class="page-name mr-auto">{{ $route.meta.name }}</div>

    <div class="top-bar">
      <router-link
        id="top"
        to="/invoice"
        class="add btn btn-primary font-weight-bold todo-list-add-btn ml-0 ml-lg-2 mt-2 mt-lg-0"
        @click="logout"
      >
        Top Up
      </router-link>
      <button
        id="logout"
        class="add btn btn-primary font-weight-bold todo-list-add-btn ml-0 ml-lg-2 mt-2 mt-lg-0"
        @click="logout"
      >
        Logout
      </button>
    </div>
  </b-navbar>
</template>

<script>
export default {
  name: "app-header",
  methods: {
    toggleSidebar: () => {
      document.querySelector("body").classList.toggle("sidebar-icon-only");
      if (window.innerWidth < 991) {
        document.querySelector("#sidebar").classList.toggle("active");
      }
    },
    toggleMobileSidebar: () => {
      document.querySelector("#sidebar").classList.toggle("active");
    },
    togglesettingsPannel: () => {
      document.querySelector("#right-sidebar").classList.toggle("open");
    },
    async logout() {
      try {
        this.$axios.post("/logout/access");
        this.$store.commit("updateLoading", false);

        this.$router.push({ name: "login" });
        localStorage.clear();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  padding: 0 2.25rem;
}
.page-name {
  margin-left: 2rem;
}
@media (max-width: 991px) {
  .top-bar {
    display: none;
  }
  .page-name {
    margin-left: 0.5rem;
  }
  .navbar {
    display: flex;
    flex-direction: row !important;
    .navbar-brand-wrapper {
      margin-left: 0;
    }
  }
}
.navbar .navbar-brand-wrapper .navbar-brand img {
  width: auto;
  height: 41px;
}
</style>